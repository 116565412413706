<template>
  <VBtn
    class="slide-count"
    :color="color"
    height="22"
    icon
    outlined
    width="23"
  >
    {{ value }}
  </VBtn>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: '#010326',
    },
  },
};
</script>
